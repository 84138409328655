import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion, useSpring, useAnimationFrame, useMotionValue } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { withArtDirection } from 'gatsby-plugin-image'

const MarqueeRow = ({ className, children, speed }) => {

	const centerRef = useRef()
	const totalLengthRef = useRef()


	// use a linear motion value that gets updated on every frame by the function on line 15
	const x = useMotionValue(-8000)
	// add a spring to this value to give it easing
	const elasticX = useSpring(x, { stiffness: 100, mass: 6, damping: 50 })
	// the tilt it a value between -1 and 1 set by the mouse x position on the page
	const [tilt, setTilt] = useState(-0.2)
	const [direction, setDirection] = useState(-1)
	// Wheel speed
	const [wheelDelta, setWheelDelta] = useState(undefined)

	// set the next x position of the motion value every single frame
	useAnimationFrame(t => {
		let currentX = x.get()
		// console.log('direction', direction)
		const left = totalLengthRef.current.getBoundingClientRect().left
		const right = totalLengthRef.current.getBoundingClientRect().right
		const width = totalLengthRef.current.offsetWidth
		// if the left edge is greater than the widow width 
		if (((left + window.innerWidth) > window.innerWidth && tilt > 0) || (left > window.innerWidth && wheelDelta > 0)) {
			x.set(23)
		} else if ((right < window.innerWidth && tilt < 0) || (right < window.innerWidth  && wheelDelta < 0)){
			// x.set(0 - (width - 22))
			x.set(window.innerWidth - width)
			// x.set(currentX)
		} else {
			x.set(currentX + (direction * speed))
		}
	})

	// add an event listener that sets the tilt
	useEffect(() => {
		const findDirection = (e) => {
			const outOfScreen = (e.clientX < 10) || (e.clientX > (window?.innerWidth - 10)) || (e.clientY < 10) || (e.clientY > (window?.innerHeight - 10))
			if (outOfScreen) {
				setDirection(0)
			} else if (e.clientX  > window?.innerWidth / 2) {
				setDirection(1)
			} else {
				setDirection(-1)
			}
		}
		window.addEventListener('mousemove', e => findDirection(e))
		return () => window.removeEventListener('mousemove', e => findDirection(e))
	}, [speed])

	useEffect(() => {
		const handleWheel = (e) => {
			setWheelDelta(e.deltaY)
			// console.log('e.deltaY', e.deltaY)
			// console.log('e.deltaY divided', e.deltaY / 10)
			e.clientY < (window.innerHeight - 324) ? null : x.set(x.current + (e.deltaY / 400))
		}

		window.addEventListener('wheel', e => handleWheel(e))
		return () => window.removeEventListener('wheel', e => handleWheel(e))
	}, [x.current])

	const bigArray = [...Array(30)]

	return (
		<Wrap className={className}>
			<MovingBlock style={{ x: elasticX }} >
				<div ref={totalLengthRef}>
					<Center>
						{bigArray.map(entry => (
							children
						))}
					</Center>
				</div>
			</MovingBlock>
		</Wrap>
	)
}

const Wrap = styled.div`
`

const MovingBlock = styled(motion.div)`
	position: relative;
	z-index: 2;
	display: inline-block;
`

const Center = styled.div`	
	white-space: nowrap;
`

MarqueeRow.propTypes = {
	className: PropTypes.string,
	children: PropTypes.array,
	speed: PropTypes.number,
}

export default MarqueeRow